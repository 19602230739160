.single-tour {
  article.hentry {
    header {
      @include banner-page-header;
      .details {
        font-size: $font-size-lg;
        .entry-title {
          margin-bottom: var(--space-sm);
        }
      }
    }
    .entry-content {
      @include headings-top-spacing;
      @include last-child-zero-margin;
      font-size: $font-size-lg;
//       margin-bottom: var(--space-lg);
      &>:first-child {
        &.tt-block {
          margin-top: calc(var(--space-lg)*-1);
        }
      }
      &>:last-child {
        &.tt-block {
          padding-bottom: 0;
        }
      }
    }
  }
}
