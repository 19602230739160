/* GRID
  --------------------------------------- */
@mixin wp-full-width {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}
@mixin alignwide-lg-up {
  @include media-breakpoint-up(lg) {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc(50% - 50vw);
    max-width: $container-fluid-max !important;
    padding-left: map-get($grid-container-margins, 'lg')/2;
    padding-right: map-get($grid-container-margins, 'lg')/2;
  }
  @media (min-width: $container-fluid-max) {
    margin-left: calc(50% - 570px);
  }
}
@mixin wp-full-width-no-padding {
  @include wp-full-width;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}


/* BLOCK ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin list-normalize-inline-block {
  @include list-normalize;
  &>li {
    display: inline-block;
  }
}
@mixin list-normalize-flexbox {
  @include list-normalize;
  @include d-flex;
  &>li {
    @include d-flex;
  }
}
@mixin list-pills {
  @include list-normalize-inline-block;
  li {
    margin: var(--gap-2xs) var(--gap-2xs) 0 0;
    a {
      @include link-no-underline;
      display: inline-block;
      background-color: var(--color-slate);
      padding: var(--gap-2xs) var(--gap-xs);
      font-size: var(--text-xs);
      &:hover,&:active,&:focus,&:focus-visible {
        background-color: var(--color-yellow);
      }
    }
  }
}
@mixin list-pills-nolink {
  @include list-normalize-inline-block;
  li {
    margin: var(--gap-2xs) var(--gap-2xs) 0 0;
    background-color: rgba($black,.06);
    padding: var(--gap-2xs) var(--gap-xs);
    font-size: var(--text-xs);
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin last-child-zero-margin-important {
  &>:last-child {
    margin-bottom: 0 !important;
  }
}
@mixin paragraph-spacing {
//   margin-bottom: var(--space-sm);
  margin-bottom: var(--space-base);
}
@mixin wp-block-spacing {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}
@mixin headings-top-spacing {
  &>h2,&>.h2,&>h3,&>.h3,&>h4,&>.h4,&>h5,&>.h5,&>h6,&>.h6 {
    margin-top: var(--space-sm);
  }
}
@mixin banner-page-header {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: var(--leading-normal);
  figure {
    @include figure-aspect-ratio('12/5');
    margin-bottom: 0;
/*
    @include media-breakpoint-up(lg) {
      @include figure-aspect-ratio('14/5');
    }
    @include media-breakpoint-up(xl) {
      @include figure-aspect-ratio('17/5');
    }
*/
  }
  .details {
    padding-top: calc(var(--space-sm)*2);
    padding-bottom: calc(var(--space-sm)*2);
    .entry-title {
      @include banner-title;
    }
    &>.row {
      &>div[class*="col-"] {
        @include media-breakpoint-up(md) {
          @include last-child-zero-margin;
        }
        @include media-breakpoint-up(lg) {
          &:first-child {
            padding-right: var(--space-sm);
          }
          &:last-child {
            padding-left: var(--space-sm);
          }
        }
      }
    }
  }
}
@mixin title-header {
  &.bg-black {
    --color-body-text: var(--color-white);
  }
  margin-top: calc(var(--space-lg)*-1);
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
  text-align: center;
  h1 {
    @include font-header;
  }
  .search-form {
    max-width: 400px;
    margin: var(--space-sm) auto 0;
  }
}


/* IMAGES
  --------------------------------------- */
@mixin figure-aspect-ratio($ratio) {
  aspect-ratio: #{$ratio};
  overflow: hidden;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}


/* LINKS & ACTIONS
  --------------------------------------- */
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-underline-hover {
  text-decoration: none;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  text-decoration: none;
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin link-caret-transition {
  i[class*="fa-"] {
    @include transition($caret-transition);
  }
  &:not(.collapsed) {
    i[class*="fa-"] {
      transform: rotate(180deg);
    }
  }
}

/*
@mixin link-icon-animation {
  @include link-no-underline;
  font-weight: var(--weight-bold);
  letter-spacing: var(--tracking-tight);
  i[class*="fa-"] {
    @include transition($link-transition);
    margin-left: var(--gap-xs);
  }
  &:hover,&:active,&:focus,&:focus-visible {
    color: var(--link-color);
    i[class*="fa-"] {
      margin-left: var(--gap-sm);
    }
  }
}
*/
@mixin focus-outline {
  outline: 2px solid var(--link-color-focus-visible);
}
@mixin tile-hover {
  --link-color-hover: var(--link-color);
  @include link-no-underline;
  figure {
    overflow: hidden;
    img {
      will-change: transform, opacity;
      transition: all ease .4s;
    }
  }
  .readmore {
    font-size: var(--text-base);
  }
  .entry-title {
    @include transition($link-transition);
    --link-color-hover: var(--link-color-hover);
  }
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.05);
      }
    }
    .readmore {
      i[class*="fa-"] {
        margin-left: var(--gap-sm);
      }
    }
  }
}
@mixin tile-hover-light {
  @include tile-hover;
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.03);
      }
    }
  }
}
@mixin tile-grid {
  article.hentry {
    margin-bottom: var(--space-sm);
    a.article-link {
      @include link-no-underline;
      @include tile-hover-light;
      height: 100%;
      flex-direction: column-reverse;
      &>figure {
        @include figure-aspect-ratio('8/5');
        margin-bottom: var(--space-sm);
      }
      &>header {
        @include last-child-zero-margin;
        @include d-flex;
        flex-direction: column;
        flex: 1;
        .entry-title {
          @include transition($link-transition);
          margin: 0 0 var(--gap-sm);
        }
        .entry-excerpt {
          @include last-child-zero-margin;
          font-size: $font-size-sm;
          color: var(--article-excerpt-color);
          margin: 0 0 var(--space-sm);
          p {
            margin-bottom: var(--space-base);
          }
        }
        .icon-indicator {
          color: var(--link-color-hover);
          font-size: var(--text-3xl);
          i[class*="fa-"] {
//             @include transition($indicator-transition);
          }
        }
      }
      &:hover,&:active,&:focus,&:focus-visible {
        &>header {
          .icon-indicator {
            i[class*="fa-"] {
              margin-left: .25em;
            }
          }
        }
      }
    }
  }
}

/* FONTS
  --------------------------------------- */
@mixin font-variation($wdth) {
  font-variation-settings: 'wdth' #{$wdth};
}
@mixin font-condensed-caps {
  font-variation-settings: 'wdth' 75;
  text-transform: uppercase;
}
@mixin font-header {
  font-variation-settings: 'wdth' 90;
  text-transform: none;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
@mixin condensed-h2-lg {
  @include font-condensed-caps;
  margin: 0 0 0 !important;
  font-size: var(--text-5xl);
}
@mixin banner-title {
  font-variation-settings: 'wdth' 75;
  line-height: .9;
  font-size: var(--text-7xl);
  text-transform: none;
  @include media-breakpoint-up(lg) {
    padding-right: var(--space-md);
  }
  .home & {
    font-size: var(--text-8xl);
    @include media-breakpoint-up(md) {
      line-height: var(--leading-negative);
    }
    @include media-breakpoint-up(lg) {
      padding-right: var(--space-xl);
    }
  }
}


/* GRID
  --------------------------------------- */
@mixin main-column-width-narrow {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}



