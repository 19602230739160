// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
body {
  main.main {
    padding-bottom: var(--space-lg);
  }
  &:not(.blog):not(.archive):not(.home) {
    main.main {
      @include main-column-width-narrow;
    }
  }
  &:not(.blog):not(.single-post):not(.single-tour):not(.single-resource), &.paged {
    main.main {
      padding-top: var(--space-lg);
    }
  }
}
