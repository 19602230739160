.bg-black {
  --color-body-bg: var(--color-black);
  --color-body-text: var(--color-yellow);
  --link-color: var(--color-yellow);
  --link-color-hover: var(--color-white);
  --btn-color: var(--color-black);
  --btn-color-hover: var(--color-black);
  --btn-outline-bg: var(--color-black);
  --btn-outline-bg-hover: var(--color-gray);
}
.bg-gray {
  --color-body-bg: var(--color-gray);
  --color-body-text: var(--color-white);
  --link-color: var(--color-white);
  --link-color-hover: var(--color-yellow);
  --btn-color: var(--color-gray);
  --btn-color-hover: var(--color-gray);
  --btn-outline-bg: var(--color-gray);
  --btn-outline-bg-hover: var(--color-black);
}
.bg-black,.bg-gray {
  --btn-bg: var(--color-yellow);
  --btn-bg-hover: var(--color-white);
  --btn-outline-color: var(--color-white);
  --btn-outline-color-hover: var(--color-white);
  --btn-outline-border-base: var(--btn-outline-color);
  --btn-outline-border-hover: var(--btn-outline-border-base);
}
.bg-white {
  --color-body-bg: var(--color-white);
}
.bg-slate {
  --color-body-bg: var(--color-slate);
  --btn-color: var(--color-slate);
  --btn-color-hover: var(--color-slate);
  --btn-outline-bg: var(--color-slate);
  --btn-outline-bg-hover: var(--color-white);
}
.bg-yellow {
  --color-body-bg: var(--color-yellow);
  --btn-color: var(--color-yellow);
  --btn-color-hover: var(--color-yellow);
  --btn-outline-bg: var(--color-yellow);
  --btn-outline-bg-hover: var(--color-white);
}
.bg-white,.bg-slate,.bg-yellow  {
  --color-body-text: var(--color-black);
  --link-color: var(--color-black);
}
[class*="bg-"] {
  background: var(--color-body-bg);
  color: var(--color-body-text);
}
.btn-blue {
  --btn-color: var(--color-white);
  --btn-color-hover: var(--color-white);
  --btn-bg: var(--color-blue);
  --btn-bg-hover: var(--color-gray);
  --btn-outline-bg: var(--color-blue);
  --btn-outline-bg-hover: var(--color-gray);
}
