html {
  @include font-variation('100');
  font-size: var(--text-base);
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: var(--space-sm);
/*
  @include media-breakpoint-up(lg) {
    scroll-padding-top: var(--space-lg);
  }
*/
}
a {
  @include transition($link-transition);
  color: var(--link-color);
  &:hover,&:active,:focus {
    color: var(--link-color-hover);
  }
  &:focus-visible {
    @include focus-outline;
  }
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus, &:focus-visible {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
  &.brand {
    display: block;
    max-width: 210px;
  }
/*
  &.readmore {
    @include link-icon-animation;
  }
*/
}
img {
  @include img-fluid;
}


/* FONTS
  --------------------------------------- */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  @include font-header;
  margin-bottom: var(--space-base);
/*
  color: var(--headline-color);
  &.color-alt {
    color: var(--headline-color-alt);
  }
*/
}
h1 {
  @include font-condensed-caps;
  margin-bottom: var(--space-base2x);
}
p {
  @include paragraph-spacing;
}

.readmore {
  @include link-no-underline;
  font-weight: var(--weight-bold);
  letter-spacing: var(--tracking-tight);
  i[class*="fa-"] {
    @include transition($link-transition);
    margin-left: var(--gap-xs);
  }
}
a.readmore {
  &:hover,&:active,&:focus,&:focus-visible {
    i[class*="fa-"] {
      margin-left: var(--gap-sm);
    }
  }
}

