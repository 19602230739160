footer.content-info {
  padding-top: var(--space-lg);
  .brand {
    margin-bottom: calc(var(--space-xs)*1.5);
  }
  .newsletter-signup {
    @include media-breakpoint-down(md) {
      margin-bottom: var(--space-lg);
    }
    @include media-breakpoint-up(lg) {
      padding-right: var(--space-md);
    }
    p {
      font-weight: var(--weight-bold);
      margin-bottom: calc(var(--space-xs)*1.5);
    }
    form {
      margin-bottom: var(--space-xs);
    }
    small {
      display: block;
      font-size: var(--text-xs);
      color: var(--color-gray);
      a {
        --link-color: var(--color-gray);
//         --link-color-hover: var(--color-black);
      }
    }
  }
  nav:not(.nav-footer-legal) {
    h3 {
      @include font-condensed-caps;
      font-size: var(--text-base);
      margin-bottom: var(--space-base);
    }
    ul {
      @include list-normalize;
      li {
        a {
//           --link-color-hover: var(--color-black);
          @include link-underline-hover;
          display: block;
          font-weight: var(--weight-semibold);
          font-size: var(--text-sm);
          line-height: var(--leading-none);
          padding: var(--gap-xs) 0;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: var(--space-lg);
    }
    &.nav-social {
      ul {
        li {
          margin-bottom: 3px;
          a {
            @include link-no-underline;
            @include d-flex;
            align-items: center;
            padding: var(--gap-xs);
            background-color: var(--color-slate);
            i[class*="fa-"] {
              width: $footer-icon-size;
              height: $footer-icon-size;
              line-height: $footer-icon-size;
              font-size: var(--text-lg);
              margin-right: var(--gap-sm);
              text-align: center;
            }
            &:hover,&:active,&:focus,&:focus-visible {
              background-color: var(--color-yellow);
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
  .copyright-legal {
    margin-top: var(--space-lg);
    padding: calc(var(--space-lg)/2) 0;
    font-size: var(--text-xs);
    line-height: var(--leading-none);
    .copyright {
      margin-bottom: var(--space-base);
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
    .nav-footer-legal {
      ul {
        @include list-normalize-inline-block;
        @include media-breakpoint-up(md) {
          text-align: right;
        }
        li {
          margin: 0 var(--gap-lg) 0 0;
          @include media-breakpoint-up(md) {
            margin: 0 0 0 var(--gap-lg);
          }
          a {
            @include link-underline-hover;
          }
        }
      }
    }
  }
}
