.form-control {
  border: 1px solid var(--input-border-color);
  height: auto;
  line-height: var(--leading-none);
  font-weight: $input-font-weight;
  padding: $input-pad-y $input-pad-x !important;

  &:focus,&:focus-visible {
    border-color: var(--link-color-focus-visible);
  }
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
  &:focus-visible {
    @include focus-outline;
  }
}
.search-form {
  .error404 &,.page-id-16040 & {
    margin: var(--space-md) 0;
  }
}
.gform_wrapper {
  .gfield_label {
    @extend .sr-only;
  }
  input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], select, textarea {
    @extend .form-control;
  }
  .gform-button {
    @extend .btn;
  }
  &#gform_wrapper_1 {
    .gform_fields {
      display: flex !important;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      grid-column-gap: 0;
      #field_1_1 {
        flex: 1 1 auto;
        margin-right: 0;
        .validation_message {
          background-color: transparent;
          border: none;
          font-size: var(--text-xs);
          margin-top: 4px;
          padding: 0;
        }
      }
    }
    .gform_footer {
      padding: 0;
      margin: 0;
    }
    .gform_validation_errors {
      display: none;
    }
    .gform_confirmation_wrapper {
      margin-bottom: calc(var(--space-xs)*1.5);
    }
    @include media-breakpoint-down(sm) {
      input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
        line-height: var(--leading-none) !important;
      }
    }
  }
}
