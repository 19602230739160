.page-header {
  margin-bottom: var(--space-lg);
  h1 {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
    .parent-pageid-509 & {
      text-transform: none;
    }
  }
  .intro {
    ul.action {
      @include list-normalize-inline-block;
      margin-top: var(--space-base);
      li {
        margin-top: var(--space-base);
        margin-right: var(--space-sm);
      }
      .btn {
        i[class*="fa-"] {
          margin-left:  var(--gap-sm);
        }
      }
      a {
        display: inline-block;
        white-space: nowrap;
      }
    }
  }
  &.marquee-header {
    margin-top: calc(var(--space-lg)*-1);
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
    .title {
      h1 {
        @include banner-title;
      }
    }
    .intro {
      @include last-child-zero-margin;
    }
  }
  &+.tt-block {
    margin-top: calc(var(--space-lg)*-1);
  }
  &.title-header {
    @include title-header;
  }
}
.page-header-featured-image {
  margin-top: calc(var(--space-lg)*-1);
  margin-bottom: var(--space-lg);
  figure {
    @include figure-aspect-ratio('12/5');
  }
}
.link-tiles-grid {
  @include tile-grid;
  article.hentry {
    margin-bottom: var(--space-base2x);
    a.article-link {
      &>header {
        padding: var(--space-sm);
        .entry-title {
          @include d-flex;
          align-items: flex-start;
          justify-content: space-between;
          font-size: var(--text-3xl);
          margin-bottom: var(--space-base);
          span.btn {
            margin-left: var(--space-xs);
          }
        }
        .entry-excerpt {
          font-size: $font-size-base;
          line-height: var(--leading-tight);
          margin-bottom: 0;
        }
      }
      &>figure {
        margin-bottom: 0;
      }
      &:hover,&:active,&:focus,&:focus-visible {
        &>header {
          .entry-title {
            span.btn {
              background-color: var(--color-blue);
//               margin-right: calc(var(--gap-2xs)*-.75);
            }
          }
        }
      }
    }
  }
/*
  &+.tt-block {
    margin-top: 0;
  }
*/
}
body.search-results {
  article.hentry {
    margin-bottom: var(--space-sm);
    .entry-title {
      margin-bottom: var(--gap-xs);
      a {
        @include link-underline-hover;
      }
    }
    &.has-post-thumbnail {
      figure {
        max-width: 160px;
        margin-left: var(--space-sm);
      }
      @include media-breakpoint-down(sm) {
        flex-direction: row-reverse;
        figure {
          max-width: none;
          width: 100%;
          margin: 0 0 var(--space-xs);
        }
      }
    }
  }
}
