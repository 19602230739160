@function hextorgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {

  /* FONTS/TYPE
  --------------------------------------- */
  /* font-family */
    --font-sans: 'HEX Franklin Variable','Blank';
    --font-serif: var(--font-sans);
    --font-display: var(--font-sans);

  /* line-height */
    --leading-none: 1;
    --leading-negative: .8;
    --leading-tight: 1.2;
    --leading-normal: 1.5;

  /* letter-spacing */
    --tracking-tight: -0.01em;
    --tracking-normal: 0em;
    --tracking-wide: .125em;

  /* font-weight */
    --weight-normal: 400;
    --weight-semibold: 600;
    --weight-bold: 700;
    --weight-black: 800;

  /* font-size */
    --text-xs: clamp(10px, calc(10px + (12 - 10) * ((100vw - 375px) / (1600 - 375))), 12px);
    --text-sm: clamp(12px, calc(12px + (14 - 12) * ((100vw - 375px) / (1600 - 375))), 14px);
    --text-md: clamp(14px, calc(14px + (16 - 14) * ((100vw - 375px) / (1600 - 375))), 16px);
    --text-lg: clamp(16px, calc(16px + (18 - 16) * ((100vw - 375px) / (1600 - 375))), 18px);
    --text-xl: clamp(18px, calc(18px + (20 - 18) * ((100vw - 375px) / (1600 - 375))), 20px);
    --text-2xl: clamp(20px, calc(20px + (22 - 20) * ((100vw - 375px) / (1600 - 375))), 22px);
    --text-3xl: clamp(24px, calc(24px + (28 - 24) * ((100vw - 375px) / (1600 - 375))), 28px);
    --text-4xl: clamp(28px, calc(30px + (32 - 28) * ((100vw - 375px) / (1600 - 375))), 32px);
    --text-5xl: clamp(32px, calc(32px + (36 - 32) * ((100vw - 375px) / (1600 - 375))), 36px);
    --text-5xl-lg: clamp(32px, calc(32px + (40 - 32) * ((100vw - 375px) / (1600 - 375))), 40px);
    --text-6xl: clamp(36px, calc(36px + (56 - 36) * ((100vw - 375px) / (1600 - 375))), 56px);
    --text-7xl: clamp(40px, calc(40px + (76 - 40) * ((100vw - 375px) / (1600 - 375))), 76px);
    --text-8xl: clamp(48px, calc(48px + (80 - 48) * ((100vw - 375px) / (1600 - 375))), 80px);
    --text-base: var(--text-md);


  /* COLORS
  --------------------------------------- */
    --color-white: #fff;
    --color-black: #1A1916;

    --color-yellow: #FAF7D9;
    --color-slate: #F4F4F4;
    --color-gray: #4D4D4D;
    --color-blue: #0088b4;

    --color-body-text: var(--color-black);
    --color-body-bg: var(--color-white);

    --color-brand-primary:      var(--color-black);
    --color-brand-secondary:    var(--color-yellow);

    --headline-color:           var(--color-body-text);
    --headline-color-alt:       var(--color-brand-secondary);
    --overline-color:           var(--color-body-text);
    --overline-color-alt:       var(--color-brand-secondary);

    --link-color:               var(--color-black);
    --link-color-hover:         var(--color-blue);
    --link-color-focus-visible: var(--color-blue);

    --caption-border-color:     var(--color-gray);
    --blockquote-color:         var(--color-body-text);

  /* MARGINS/PADDING
  --------------------------------------- */
  /* fluid-spacer */
    --space-xs: clamp(12px, calc(12px + (16 - 12) * ((100vw - 375px) / (1600 - 375))), 16px);
    --space-sm: clamp(20px, calc(20px + (32 - 20) * ((100vw - 375px) / (1600 - 375))), 32px);
    --space-md: clamp(30px, calc(30px + (48 - 30) * ((100vw - 375px) / (1600 - 375))), 48px);
    --space-lg: clamp(50px, calc(50px + (80 - 50) * ((100vw - 375px) / (1600 - 375))), 80px);
    --space-xl: clamp(80px, calc(80px + (128 - 80) * ((100vw - 375px) / (1600 - 375))), 128px);
    --space-xxl: clamp(130px, calc(130px + (160 - 130) * ((100vw - 375px) / (1600 - 375))), 160px);
    --space-base: calc(var(--space-xs)*1.25); // 20
    --space-base2x: calc(var(--space-base)*2); // 40


  /* static gaps*/
    --static-size-base: 16px;
    --gap-base: var(--static-size-base);        // 16
    --gap-2xs: calc(var(--gap-base) * .25);     // 4
    --gap-xs: calc(var(--gap-base) * .5);       // 8
    --gap-sm: calc(var(--gap-base) * .75);      // 12
    --gap-md-s: calc(var(--gap-base) * 1.125);  // 18
    --gap-md: calc(var(--gap-base) * 1.25);     // 20
    --gap-lg: calc(var(--gap-base) * 1.5);      // 24
    --gap-lg-xl: calc(var(--gap-base) * 1.875); // 30
    --gap-xl: calc(var(--gap-base) * 2);        // 32
    --gap-2xl: calc(var(--gap-base) * 2.25);    // 36
    --gap-3xl: calc(var(--gap-base) * 3);       // 48

    --paragraph-spacing: var(--space-sm);


  /* INPUTS
  --------------------------------------- */
    --input-color: var(--color-body-text);
    --input-bg: var(--color-slate);
    --input-border-color: var(--color-slate);
    --input-border-radius: 0;
    --input-placeholder: var(--color-gray);


  /* ELEVATIONS
  --------------------------------------- */
    --elevation-sticky-toc: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
    --elevation-base: 0px 5px 12px rgba(13, 0, 38, 0.08);
    --elevation-md: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
}
