.card {
  background-color: transparent;
  background-clip: border-box;
  border: none;
  border-top: 1px solid var(--color-black);
  border-radius: 0;
  .card-body {
    padding: 0;
    color: var(--color-body-text);
  }
  .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
    &:first-child {
      border-radius: 0;;
    }
    a {
      @include link-no-underline;
      @include link-caret-transition;
      padding: var(--gap-sm) 0;
      h3 {
        @include transition($link-transition);
        margin: 0;
        font-size: var(--text-xl);
      }
      h3,i[class*="fa-"] {
        margin-right: var(--space-xs);
      }

      &:hover,&:active,&:focus,&:focus-visible {
        color: var(--link-color);
        h3 {
          color: var(--link-color-hover);
        }
      }
    }
  }
}
