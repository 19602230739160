.wp-full-width {
  @include wp-full-width-no-padding;
}
.tt-block,.tt-tour-block-group {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
  &:not(.bg-white) {
    &+.tt-block.bg-white {
      margin-top: calc(var(--space-lg)*-1);
    }
  }
  &+.tt-block:not(.bg-white) {
    margin-top: calc(var(--space-lg)*-1);
  }
  &.bg-white,&.stats-block {
    &+.tt-block.bg-white {
      margin-top: calc(var(--space-lg)*-1);
    }
    &+.learn-more-block {
      margin-top: calc(var(--space-sm)*-1);
    }
  }
}
main.main {
  @include headings-top-spacing;
  &>:first-child {
    &.tt-block {
      margin-top: 0;
    }
  }
  &>:last-child {
    &.tt-block {
      margin-bottom: calc(var(--space-lg)*-1);
    }
  }
}

/* SPECIFIC BLOCKS
  --------------------------------------- */
.accordion-block {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-md);
  .card {
    .card-body {
      --paragraph-spacing: calc(var(--space-xs)*1.5);
      font-size: var(--text-sm);
    }
  }
}
.creative-commons-block {
  padding: 0;
  margin: var(--space-md) 0;
  article {
    @include last-child-zero-margin;
    padding: var(--space-sm);
  }
}

.featured-block  {
  &>.container {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
    &>a.featured-link {
      @include tile-hover-light;
      display: block;
      &>.row {
        @include media-breakpoint-down(sm) {
          flex-direction: column-reverse;
          margin: 0;
          &>div[class*="col-"] {
            padding: 0;
          }
        }
      }
    }
  }
  .details {
    padding-right: 0;
    article {
      @include d-flex;
      flex-direction: column;
      height: 100%;
      padding: var(--space-sm);
      @include media-breakpoint-up(lg) {
        padding: var(--space-sm) calc(var(--space-xs)*2.5);
      }
      h2 {
        font-size: $h1-font-size;
      }
      .entry-content {
        @include last-child-zero-margin;
        margin-top: auto;
        ul.category {
          @include list-pills-nolink;
          margin-bottom: var(--space-sm);
        }
      }
    }
  }
  .media {
    display: block;
    @include media-breakpoint-up(md) {
      padding-left: 0;
    }
    figure {
      @include figure-aspect-ratio('7/6');
      margin-bottom: 0;
      height: 100%;
      max-width: 100%;

    }
  }
  &.cover-story {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }
  &+.featured-block {
    padding-top: 0;
    &:nth-child(even of .featured-block) {
      @include media-breakpoint-up(md) {
        &>.container {
          &>a.featured-link {
            &>.row {
              flex-direction: row-reverse;
            }
          }
        }
        .media {
          padding-right: 0;
          padding-left: $grid-gutter-width/2;
        }
        .details {
          padding-left: 0;
        }
      }
    }
  }
}
.learn-more-block {
  article {
    padding: var(--space-sm);
    .description {
      @include font-header;
      font-size: var(--text-3xl);
    }
    .action {
      @include media-breakpoint-down(sm) {
        margin-top: var(--space-sm);
      }
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
}
.related-articles-block,.related-posts-block,.link-tiles-block,.logos-block {
  .details {
    margin-bottom: var(--space-base2x);
    h2 {
      @include condensed-h2-lg;
    }
  }
}
.logos-block {
  .details {
    h2 {
      @include d-flex;
      justify-content: space-between;
      align-items: flex-start;
      .btn {
        font-variation-settings: 'wdth' 100;
        font-size: var(--text-base);
        font-weight: var(--weight-semibold);
        text-transform: none;
        margin-left: var(--space-xs);
        white-space: nowrap;
      }
    }
    .synopsis {
      @include last-child-zero-margin;
      margin-top: var(--space-base);
    }
  }
  .logo-grid {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(3, 33.33%);

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 25%);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 20%);
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(6, 16.666%);
    }
    .logo {
      background-color: var(--color-slate);
      @include d-flex;
      align-items: center;
      justify-content: center;
      figure {
        @include d-flex;
        align-items: center;
        justify-content: center;
        padding: var(--space-sm) var(--space-xs);
        margin: 0;
        img {
          @include transition($link-transition);
          filter: grayscale(1);
          opacity: .85;
        }
        a {
          @include hover-focus {
            img {
              filter: grayscale(0);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}



.related-articles-block,.related-posts-block,.link-tiles-block {
  padding-bottom: var(--space-base2x);
}
.studio-projects-block {
  article {
    padding: var(--space-md);
    margin-top: var(--space-lg);
    &:first-child {
      margin-top: 0;
    }
    .overview {
      font-size: $font-size-xl;
      h2 {
        margin-bottom: var(--space-xs);
      }
      @include media-breakpoint-up(lg) {
        @include last-child-zero-margin;
      }
    }
    .details {
      @include last-child-zero-margin;
    }
    .media {
      figure {
        @include figure-aspect-ratio('17/10');
        margin: calc(var(--space-sm)*2) 0 0;
      }
    }
  }
}
.team-block {
  .team-row {
    margin-top: var(--space-lg);
    .team-member {
      font-size: var(--text-sm);
      margin-bottom: var(--space-md);
      figure {
        @include figure-aspect-ratio('1/1');
        margin-bottom: var(--space-xs);
      }
      header {
        margin-bottom: var(--space-xs);
        h3 {
          font-size: var(--text-xl);
          margin-bottom: 0;
        }
        p {
          font-size: var(--text-md);
          margin-bottom: 0;
        }
      }
    }
  }
}
.tour-highlights-block {
  padding-bottom: var(--space-md);
  h2 {
    @include condensed-h2-lg;
    margin-bottom: var(--space-sm) !important;
  }
  ul.tour-highlights {
    @include list-normalize-keep-margin;
    margin-bottom: 0;
    li {
      @include make-col-ready();
      @include make-col(12);
      @include d-flex;
      margin-bottom: var(--space-sm);
      line-height: var(--leading-normal);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
      span {
        &.icon {
          margin-right: var(--space-xs);
          min-width: $highlight-icon-size;
          height: $highlight-icon-size;
          text-align: center;
          background-color: var(--color-body-text);
          color: var(--color-body-bg);
          line-height: 2;
          i[class*="fa-"] {
            width: $highlight-icon-size/2;
            height: $highlight-icon-size/2;
          }

        }
        &.info {
          font-size: $font-size-base;
        }
      }
    }
  }
}
.tt-tour-block-group {
  &>.wp-block-group__inner-container {
    &>h2.wp-block-heading {
      @include condensed-h2-lg;
    }
    .tour-info-item {
      font-size: $font-size-base;
      border-top: 1px solid var(--color-black);
      padding-top: var(--space-xs);
      padding-bottom: calc(var(--space-xs)*1.75);
      .title {
        h3 {
          margin-top: 0 !important;
          font-size: $h4-font-size;
        }
      }
      .details {
        @include last-child-zero-margin;
      }
    }
  }
  &+.learn-more-block {
    padding-top: 0;
    margin-top: calc(var(--space-lg)*-1.5) !important;
  }
}
.tour-info-block {
  .section-title {
    @include condensed-h2-lg;
  }
  article {
    font-size: $font-size-base;
    border-top: 1px solid var(--color-black);
    padding-top: var(--space-xs);
    padding-bottom: calc(var(--space-xs)*1.75);
    .title {
      h3 {
        font-size: $h4-font-size;
      }
    }
    .details {
      @include last-child-zero-margin;
    }
  }
}
