.single-post {
  article.hentry {
    header {
      margin-top: 0;
      line-height: var(--leading-normal);
      @include media-breakpoint-down(xs) {
        padding-top: 0;
        .media {
          padding: 0;
        }
      }
      @include media-breakpoint-up(md) {
        &>.container {
          &>.row {
            flex-direction: row-reverse;
          }
        }
      }
      .details {
        font-size: $font-size-sm;
        a {
          @include link-underline-hover;
          font-weight: $font-weight-bold;
          &.blog-link {
            font-weight: $font-weight-base;
          }
        }
        .categories {
          margin-bottom: calc(var(--space-lg)/2);
          i[class*="fa-"] {
            margin: 0 var(--gap-xs);
          }
        }
        .author {
          margin-bottom: 0;
        }
      }
      .entry-title {
        @include font-header;
        font-size: var(--text-4xl);
        margin-bottom: var(--space-md);
      }
      .media {
        figure {
          @include figure-aspect-ratio('17/10');
          margin-bottom: calc(var(--space-lg)/2);
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
      }
    }
    &>.entry-content {
      @include headings-top-spacing;
      @include last-child-zero-margin;
      font-size: $font-size-lg;
      margin-bottom: var(--space-lg);
    }
    footer {
      ul.tags {
        @include list-pills;
        margin-bottom: var(--space-md);
      }
    }
    &+.related-posts-block {
      margin-top: 0;
    }
  }
}
.articles-grid {
  @include tile-grid;
  article.hentry {
    margin-bottom: var(--space-base2x);
    a.article-link {
      &>header {
        .entry-title {
          font-size: var(--text-2xl);
        }
        .entry-excerpt {
          margin-bottom: var(--space-xs);
        }
        ul.category {
          @include list-pills-nolink;
          margin-bottom: var(--space-sm);
        }

      }
    }
  }
  &+.tt-block {
    margin-top: 0;
  }
}
.share-buttons-wrap {
  h3 {
    margin-bottom: var(--space-xs);
  }
  .socializer {
    &>span {
      margin-right: var(--gap-2xs);
    }
    a {
      @extend .btn;
      @extend .btn-icon;
      width: auto;
      height: auto;
    }
  }
}

.pagination {
  margin-top: var(--space-md);
  .nav-links {
    width: 100%;
    justify-content: center;
    &>a,&>span {
      @extend .btn;
      @extend .btn-icon;
      margin: 0 calc(var(--gap-2xs)/2);
      height: $btn-icon-size;
      width: $btn-icon-size;
      --btn-bg: var(--color-slate);
      --btn-bg-hover: var(--color-yellow);
      --btn-color: var(--color-black);
      --btn-color-hover: var(--btn-color);
      &:not(.prev):not(.next) {
        line-height: 1.25;
      }
      &.current {
        --btn-bg: var(--color-black);
        --btn-bg-hover: var(--btn-bg);
        --btn-color: var(--color-white);
        --btn-color-hover: var(--btn-color);
      }
      &.inactive {
        --btn-bg-hover: var(--btn-bg);
        opacity: .5;
      }
      &.prev,&.next,&.dots {
//         padding: var(--gap-sm) calc(var(--gap-sm)*1.1) !important;
      }
      &.prev {
        margin-left: 0;
        margin-right: auto;
      }
      &.next {
        margin-right: 0;
        margin-left: auto;
      }
/*
      @include media-breakpoint-down(sm) {
        font-size: var(--text-sm) !important;
        margin: 0 calc(var(--gap-2xs)*.75);
      }
*/
    }
    &>span {
      cursor: default !important;
    }
  }
}
.single-resource {
  article.hentry {
    header {
      @include title-header;
      margin-top: 0;
      .entry-title {
        margin-bottom: 0;
      }
    }
  }
}
/* NAVS
  --------------------------------------- */
nav.posts-navigation {
  margin-bottom: var(--space-lg);
  .nav-links {
    @include make-row();
    &>div {
      @include make-col-ready();
      @include make-col(6);
      &.nav-next {
        margin-left: auto;
        text-align: right;
      }
      a {
        @extend .btn;
      }
    }
  }
}


