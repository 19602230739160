/* GRID SETTINGS
  --------------------------------------- */
$grid-gutter-widths: (
  xs: 30px,
  sm: 30px,
  md: 30px,
  lg: 32px,
  xl: 32px
);
$grid-container-margins: (
  xs: 30px,
  sm: 30px,
  md: 30px,
  lg: 32px,
  xl: 32px
);

$grid-gutter-width:         30px;
$main-sm-columns:           12;
$sidebar-sm-columns:        4;

$container-fluid-max:       1140px;
$container-fluid-max-half:  $container-fluid-max/2;


/* COLORS
  --------------------------------------- */
$body-color:            var(--color-body);
$body-bg:               var(--color-bg);
$brand-primary:         var(--color-brand-primary);
$brand-secondary:       var(--color-brand-secondary);

$link-color:            var(--link-color);
$link-hover-color:      var(--link-color-hover);
$link-decoration:       underline;
$link-hover-decoration: underline;
$link-transition:       all .125s ease-in-out;
$indicator-transition:  all .25s ease-in-out;
$caret-transition:      transform .08s linear;


/* FONTS
  --------------------------------------- */
$font-family-sans:      var(--font-sans);
$font-family-serif:     var(--font-serif);
$font-family-awesome:   'Font Awesome 6 Pro';
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;             //  16
$font-size-xs:          var(--text-xs);   //  12
$font-size-sm:          var(--text-sm);   //  14
$font-size-lg:          var(--text-lg);   //  18
$font-size-xl:          var(--text-xl);   //  20

$h1-font-size-lg:       var(--text-5xl-lg);// 40

$h1-font-size:          var(--text-5xl);  //  36
$h2-font-size:          var(--text-4xl);  //  32
$h3-font-size:          var(--text-3xl);  //  28
$h4-font-size:          var(--text-2xl);  //  22
$h5-font-size:          var(--text-xl);   //  20
$h6-font-size:          var(--text-lg);   //  18


$font-weight-normal:    var(--weight-normal);
$font-weight-semibold:  var(--weight-semibold);
$font-weight-bold:      var(--weight-bold);
$font-weight-black:     var(--weight-black);
$font-weight-base:      $font-weight-normal;

$line-height-base:      1.6;

$display-font-family:   $font-family-base;
$headings-font-family:  $font-family-base;
$headings-font-weight:  $font-weight-black;
$headings-line-height:  var(--leading-tight);

$footer-icon-size:      24px;
$highlight-icon-size:   36px;
$btn-icon-size:         40px;


/* FORM INPUTS
  --------------------------------------- */
$input-pad-y:                    12px;
$input-pad-x:                    20px;
$input-font-size:                var(--text-base);
$input-font-weight:              var(--weight-semibold);
$input-line-height:              1;
$input-height:                   auto;
$input-bg:                       var(--input-bg);
$input-disabled-bg:              var(--input-bg);
$input-color:                    var(--input-color);
$input-border-color:             var(--input-border-color);
$input-border-width:             0;
$input-box-shadow:               none;
$input-border-radius:            var(--input-border-radius);
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;
$input-focus-box-shadow:         none;
$input-placeholder-color:        var(--input-placeholder);


/* MISC OVERRIDES
  --------------------------------------- */
$navbar-brand-height:               auto;
$navbar-brand-padding-y:            auto;
$nav-link-height:                   auto;

