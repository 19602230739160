header.banner {
  padding: var(--space-base) 0;
  .mobile-toggle {
    text-align: right;
    .navbar-toggler {
      font-size: var(--text-2xl);
      padding-right: 0;
      color: var(--color-body-text);
      i {
        color: var(--color-body-text) !important;
      }
      i.fa-bars {
        display: none;

      }
      &.collapsed {
        i.fa-bars {
          display: inline-block;
        }
        i.fa-times {
          display: none;
        }
      }
    }
  }
  nav.nav-primary {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(8);
      @include d-flex;
      align-items: center;
      justify-content: flex-end;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }
    ul {
      @include list-normalize;
      @include media-breakpoint-down(sm) {
        padding: $grid-gutter-width/2;
      }
      li {
        @include media-breakpoint-down(sm) {
          font-size: $font-size-lg;
          margin: $grid-gutter-width/3 0;
        }
        a {
          @include link-underline-hover;
          @include font-header;
        }
      }
      @include media-breakpoint-up(md) {
        @include list-inline-block;
        justify-content: flex-end;
        li {
          margin-right: var(--space-xs);
        }
      }
      @include media-breakpoint-up(lg) {
        li {
          margin-right: var(--space-sm);
        }
      }
    }
    .btn {
      @include media-breakpoint-down(sm) {
        margin: 0 0 $grid-gutter-width/2 $grid-gutter-width/2;
      }
    }
  }
}
