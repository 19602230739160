// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}


// ELEMENTS
.wp-block-buttons {
  @include paragraph-spacing;
  padding-top: calc(var(--space-xs)/2);
  padding-bottom: calc(var(--space-xs)/2);
}


.wp-block-media-text {
  display: grid;
  grid-gap: 20px;
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}


// IMAGES
.wp-block-image, figure.wp-block-image {
  @include wp-block-spacing;
  figcaption,.wp-element-caption {
    margin-top: var(--gap-xs);
    margin-bottom: 0;
    padding: 0 0 0 var(--gap-xs);
    border-left: 2px solid var(--caption-border-color);
    line-height: var(--leading-normal);
    font-size: $font-size-xs;
    color: var(--caption-border-color);
  }
}
.wp-block-image figure, figure.wp-block-image {
  display: table;
  figcaption {
    display: table-caption;
    caption-side: bottom;
  }
}
.alignwide {
  @include wp-full-width;
  max-width: $container-fluid-max !important;
  padding-left: map-get($grid-container-margins, 'xs')/2;
  padding-right: map-get($grid-container-margins, 'xs')/2;
  @include media-breakpoint-up(lg) {
    padding-left: map-get($grid-container-margins, 'lg')/2;
    padding-right: map-get($grid-container-margins, 'lg')/2;
  }
  @media (min-width: $container-fluid-max) {
    margin-left: calc(50% - 570px);
  }
  &figure {
    &>img {
      width: 100%;
    }
    &>figcaption {
      margin-left: map-get($grid-container-margins, 'xs')/2;
      margin-right: map-get($grid-container-margins, 'xs')/2;
      @include media-breakpoint-up(lg) {
        margin-left: map-get($grid-container-margins, 'lg')/2;
        margin-right: map-get($grid-container-margins, 'lg')/2;
      }
    }
  }
}
figure.alignfull {
  @include wp-full-width;
  &>img {
    width: 100%;
  }
}
.tt-tour-block-group {
  @include alignwide-lg-up;
}

// BLOCKQUOTES
blockquote.wp-block-quote,figure.wp-block-pullquote {
  @include wp-block-spacing;
  @include last-child-zero-margin;
  color: var(--blockquote-color);
  border-left: 2px solid var(--blockquote-color);
  padding: 0 0 0 calc(var(--space-xs)*1.5);
  font-size: var(--text-2xl);
  text-align: left;
  p {
    margin-bottom: calc(var(--space-xs)/2);
  }
  cite {
    font-size: $font-size-sm;
    font-style: normal;
  }
  blockquote {
    @include last-child-zero-margin;
  }
}
