:root {
  --btn-color: var(--color-white);
  --btn-color-hover: var(--color-white);
  --btn-color-active: var(--btn-color-hover);
  --btn-color-focus: var(--btn-color-hover);
  --btn-bg: var(--color-black);
  --btn-bg-hover: var(--color-blue);
  --btn-bg-active: var(--btn-bg-hover);
  --btn-bg-focus: var(--btn-bg-hover);
  --btn-border-base: transparent;
  --btn-border-hover: var(--btn-border-base);
  --btn-border-active: var(--btn-border-base);
  --btn-border-focus: var(--btn-border-base);
  --btn-border-focus-visible: var(--color-status-success);

  --btn-outline-color: var(--color-black);
  --btn-outline-color-hover: var(--color-black);
  --btn-outline-bg: var(--color-white);
  --btn-outline-bg-hover: var(--color-yellow);
  --btn-outline-border-base: var(--btn-outline-color);
  --btn-outline-border-hover: var(--btn-outline-border-base);
}
.btn,.btn:not(:disabled):not(.disabled),.wp-block-button>a.wp-element-button {
  @include transition($link-transition);
  font-size: var(--text-base);
  font-weight: var(--weight-semibold);
  line-height: var(--leading-none);
  box-shadow: none !important;
  padding: 13px 24px;
  border: 1px solid var(--btn-border-base);
  border-radius: 0;
  position: relative;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  cursor: pointer;
  &:hover, &:active, &:focus, &:focus-visible {
    color: var(--btn-color-hover);
    background-color: var(--btn-bg-hover);
    border-color: var(--btn-border-hover);
    text-decoration: none;
  }
  &:focus-visible {
    @include focus-outline;
  }
  &.btn-outline {
    @include font-condensed-caps;
    padding: 6px;
    font-weight: var(--weight-black);
    color: var(--btn-outline-color);
    background-color: var(--btn-outline-bg);
    border: 5px solid var(--btn-outline-border-base);
    &:hover, &:active, &:focus, &:focus-visible {
      color: var(--btn-outline-color-hover);
      background-color: var(--btn-outline-bg-hover);
      border-color: var(--btn-outline-border-hover);
      text-decoration: none;
    }
  }
  &.btn-lg {
    font-size: var(--text-2xl);
  }
  &.btn-icon {
    padding: var(--gap-xs);
    [class*=fa-] {
      font-size: var(--text-2xl);
      width: var(--text-2xl);
      height: var(--text-2xl);
    }
  }
}
